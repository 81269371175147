var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-5"},_vm._l((_vm.groupedProductsByType),function(type,index){return _c('v-col',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.visibilityChanged,
      intersection: {
        root: null,
        rootMargin: '-130px 0px -60% 0px',
        threshold: 0,
      },
    }),expression:"{\n      callback: visibilityChanged,\n      intersection: {\n        root: null,\n        rootMargin: '-130px 0px -60% 0px',\n        threshold: 0,\n      },\n    }"}],key:index,staticClass:"pa-0",class:index + 1 == _vm.groupedProductsByType.length
        ? 'last-section-min-height-cover'
        : '',attrs:{"cols":"12","id":type.id}},[_c('v-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h3 font-weight-bold underline"},[_vm._v(" "+_vm._s(type.name)+" ")])]),_vm._l((type.products),function(product,index){return _c('ul',{key:index,staticClass:"py-0 menu-list",attrs:{"id":index === 0 ? type.domScrollTarget : ''}},[_c('content-card-single',{ref:type + index,refInFor:true,attrs:{"can-redirect-to-product":_vm.canRedirectToProduct,"uuid":product.uuid,"name":product.name,"description":product.description,"stock":product.stock,"isProductAvailable":product.isProductAvailable || _vm.hasToCheckAvailability === false,"price":product.origins.find(
            (origin) => String(origin.name) == String(_vm.originName)
          )
            ? product.origins.find(
                (origin) => String(origin.name) == String(_vm.originName)
              ).price
            : 0,"image":product.image,"secondaryPreviewImage":product.secondaryPreviewImage}})],1)})],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }