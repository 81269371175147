<template>
  <div class="pb-5">
    <v-col
      v-for="(type, index) in groupedProductsByType"
      :key="index"
      cols="12"
      class="pa-0"
      :class="
        index + 1 == groupedProductsByType.length
          ? 'last-section-min-height-cover'
          : ''
      "
      v-observe-visibility="{
        callback: visibilityChanged,
        intersection: {
          root: null,
          rootMargin: '-130px 0px -60% 0px',
          threshold: 0,
        },
      }"
      :id="type.id"
    >
      <v-col cols="12" class="pb-2">
        <span class="text-h3 font-weight-bold underline">
          {{ type.name }}
        </span>
      </v-col>

      <ul
        :id="index === 0 ? type.domScrollTarget : ''"
        v-for="(product, index) in type.products"
        :key="index"
        class="py-0 menu-list"
      >
        <content-card-single
          :can-redirect-to-product="canRedirectToProduct"
          :uuid="product.uuid"
          :name="product.name"
          :description="product.description"
          :stock="product.stock"
          :isProductAvailable="
            product.isProductAvailable || hasToCheckAvailability === false
          "
          :price="
            product.origins.find(
              (origin) => String(origin.name) == String(originName)
            )
              ? product.origins.find(
                  (origin) => String(origin.name) == String(originName)
                ).price
              : 0
          "
          :ref="type + index"
          :image="product.image"
          :secondaryPreviewImage="product.secondaryPreviewImage"
        />
      </ul>
    </v-col>
  </div>
</template>
<script>
import ContentCardSingle from "@/components/ContentCardSingle";

export default {
  components: {
    ContentCardSingle,
  },
  data: () => {
    return {
      currentProductId: null,
    };
  },
  props: {
    currentCategoryId: {
      type: Number,
      default: null,
    },
    groupedProductsByType: {
      type: Array,
      default: () => [],
    },
    originName: {
      type: String,
      default: null,
    },
    canRedirectToProduct: {
      type: Boolean,
      default: false,
    },
    hasToCheckAvailability: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    hasToCheckAvailability() {
      console.log("hasToCheckAvailability", this.hasToCheckAvailability);
    },
  },
  methods: {
    // capitalizeFirstLetter(string) {
    //   string = string.toLowerCase();
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$store.commit(
          "menu/SET_CURRENT_VISIBLE_SECTION_ID",
          entry.target.id
        );
      }
    },
  },
};
</script>
<style scoped>
.menu-list {
  padding: 0px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.3);*/
}
.underline {
  text-decoration: underline;
}
.menu-list:last-child {
  margin-bottom: 20px;
}
</style>
<style lang="sass" scoped>
.last-section-min-height-cover
  min-height: 70vh
</style>
