<template>
  <div v-if="showPopup">
    <!-- Fondo oscuro para el popup -->
    <div class="overlay" @click="closePopup"></div>

    <!-- Contenido del popup -->
    <div class="popup">
      <div class="button-container">
        <span class="close-btn" @click="closePopup">&times;</span>
      </div>

      <div v-if="popUpUrl != null && popUpUrl != ''">
        <a :href="popUpUrl" target="_blank">
          <img :src="imageUrl" alt="Noticias" />
        </a>
      </div>
      <div v-else>
        <img :src="imageUrl" alt="Noticias" />
      </div>

      <div class="checkbox-container">
        <input
          type="checkbox"
          id="dontShowAgain"
          v-model="dontShowAgain"
          @change="updateDontShowAgain"
        />
        <label for="dontShowAgain" class="label-popup"
          >No volver a mostrar</label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: true, //Mostrar o no el popup
      dontShowAgain: false, // No volver a mostrar
    };
  },
  mounted() {
    var localStorageUuid = localStorage.getItem(this.uuidPopUp);

    if (
      this.imageUrl === "" ||
      this.imageUrl === null ||
      localStorageUuid == "true" ||
      this.imageUrl === undefined
    ) {
      this.showPopup = false;
    }
  },
  computed: {
    imageUrl() {
      return this.$store.state.menu.profile.urlPopUpImage;
    },
    popUpUrl() {
      return this.$store.state.menu.profile.popUpUrl;
    },
    uuidPopUp() {
      return this.$store.state.menu.profile.uuidPopUp;
    },
  },
  methods: {
    updateDontShowAgain() {
      localStorage.setItem(this.uuidPopUp, this.dontShowAgain);
    },
    closePopup() {
      this.showPopup = false;
      this.updateDontShowAgain();
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 15px 15px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.popup img {
  max-width: 100%;
  height: auto;
}

.button-container {
  display: flex;
  align-items: center;
}

.close-btn {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 40px;
}

.label-popup {
  margin-left: 10px !important;
}

.close-btn:hover {
  color: red;
}

.checkbox-container {
  margin-top: 10px;
}

/* Para pantallas grandes */
@media (min-width: 768px) {
  .popup {
    width: 50%;
    max-width: 800px;
  }

  .close-btn {
    font-size: 40px;
  }
}

/* Para celulares */
@media (max-width: 767px) {
  .popup {
    width: 95%;
    max-width: 350px;
  }

  .close-btn {
    font-size: 30px;
  }
}
</style>
