<template>
  <!-- <div class="pb-5">
    <v-col
      v-for="(shop, index) in childShops"
      :key="index"
      cols="12"
      class="pa-0"
      :class="
        index + 1 == childShops.length ? 'last-section-min-height-cover' : ''
      "
      :id="shop.code"
    >
      <shop-card-single
        v-if="shop.isOpen"
        :code="shop.code"
        :name="shop.name"
        :description="shop.description"
        :ref="shop.code + index"
        :image="shop.imageUrl"
      />
    </v-col>
  </div> -->
  <!-- 
  class="shop-card-container"
      :class="
        index + 1 == childShops.length ? 'last-section-min-height-cover' : ''
      " -->

  <div class="shop-card-list-container">
    <div
      class="shop-card-list-looper"
      v-for="(shop, index) in childShops"
      :key="index"
      :id="shop.code"
    >
      <div class="shop-card-container" v-if="shop.isOpen">
        <shop-card-single
          :code="shop.code"
          :name="shop.name"
          :description="shop.description"
          :ref="shop.code + index"
          :image="shop.imageUrl"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ShopCardSingle from "@/components/ShopCardSingle";

export default {
  components: {
    ShopCardSingle,
  },
  data: () => {
    return {};
  },
  props: {
    childShops: {
      type: Array,
      default: () => [],
    },
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
.last-section-min-height-cover
  min-height: 70vh
</style>
<style scoped>
.shop-card-list-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.shop-card-list-looper {
  display: contents;
}

.shop-card-container {
  width: 50%;
  padding: 10px 10px;
}
</style>
