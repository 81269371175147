<template>
  <base-section id="menu" space="0">
    <v-container v-if="backgroundMenuUrl">
      <v-img height="100%" width="100%" :src="backgroundMenuUrl" />
    </v-container>
    <v-container v-else-if="hasChildShops">
      <v-container v-if="shopDescription">
        <div
          v-if="shopDescription"
          class="text-center font-weight-thin"
          v-html="shopDescription"
        ></div>
      </v-container>
      <v-slide-x-transition
        hide-on-leave
        v-if="
          !loadingContent &&
          webordersValidation &&
          isThereAtLeastOneChildShopOpen
        "
      >
        <div>
          <shop-card-list :childShops="childShops" />
        </div>
      </v-slide-x-transition>
      <base-section v-else space="0">
        <v-row v-if="loadingContent" justify="center" class="py-12">
          <base-progress-circular />
        </v-row>
        <v-row v-else class="pt-12" justify="center">
          <v-col cols="10" class="text-center">
            <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
              mdi-cart-off
            </v-icon>
          </v-col>

          <v-col cols="10">
            <base-subheading
              class="text-center text--disabled"
              :title="errorMessage"
            />
          </v-col>
        </v-row>
      </base-section>
    </v-container>
    <v-container v-else class="menu-container">
      <!-- Descripcion de la tienda -->
      <v-container v-if="shopDescription">
        <div
          v-if="shopDescription && !isMenu"
          class="text-center font-weight-thin"
          v-html="shopDescription"
        ></div>
      </v-container>
      <!-- Si la tienda esta abierta y .... -->
      <v-slide-x-transition
        v-if="
          groupedProductsByType &&
          groupedProductsByType[0] &&
          !loadingContent &&
          ((webordersValidation && isShopOpen) || isMenu) &&
          isPickupUpPointOpen
        "
        hide-on-leave
      >
        <div>
          <content-card-list
            :can-redirect-to-product="canRedirectToProduct"
            :groupedProductsByType="groupedProductsByType"
            :originName="originName"
            :hasToCheckAvailability="hasToCheckAvailability"
          />
        </div>
      </v-slide-x-transition>
      <!-- Sino muestra el cartel de error -->
      <base-section v-else space="0">
        <v-row v-if="loadingContent" justify="center" class="py-12">
          <base-progress-circular />
        </v-row>
        <v-row v-else class="pt-12" justify="center">
          <v-col cols="10" class="text-center">
            <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
              mdi-cart-off
            </v-icon>
          </v-col>

          <v-col cols="10">
            <base-subheading
              class="text-center text--disabled"
              :title="errorMessage"
            />
          </v-col>
        </v-row>
      </base-section>
    </v-container>
    <modal-news />
  </base-section>
</template>

<script>
import ContentCardList from "@/components/ContentCardList";
import ShopCardList from "@/components/ShopCardList";
import shopUtils from "@/mixins/shopUtils";
import stock from "@/mixins/stock";
import ModalNews from "@/components/ModalNews";
export default {
  name: "MenuSection",
  mixins: [shopUtils, stock],
  components: {
    ContentCardList,
    ShopCardList,
    ModalNews,
  },
  data() {
    return {
      selectedPickUpPoint: this.defaultPickUpPoint,
    };
  },
  watch: {
    "$store.state.deliveryCart.selectedPickUpPoint": {
      deep: true,
      handler() {
        this.selectedPickUpPoint = this.defaultPickUpPoint;
      },
    },
    selectedPickUpPoint() {
      this.$store.dispatch(
        "deliveryCart/setPickUpPoint",
        this.selectedPickUpPoint
      );
    },
  },
  async mounted() {
    await this.$store
      .dispatch("deliveryCart/initializeOrder", this.$route.query)
      .finally(() => {
        this.selectedPickUpPoint = this.defaultPickUpPoint;
      });
  },
  computed: {
    hasToCheckAvailability() {
      const result = location.href.includes("menu");
      return result;
    },
    shopDescription() {
      return this.$store.state.menu.profile.shopDescription;
    },
    backgroundMenuUrl() {
      return this.$store.getters["menu/backgroundMenuUrl"];
    },
    groupedProductsByType() {
      let groupedProductsByTypeWithStock =
        this.$store.getters["menu/groupedProductsByType"];
      groupedProductsByTypeWithStock.forEach((x) => {
        x.products = x.products.filter((y) => this.hasStock(y.stock));
      });

      groupedProductsByTypeWithStock = groupedProductsByTypeWithStock.filter(
        (x) => x.products.length > 0
      );

      return groupedProductsByTypeWithStock;
    },
    originName() {
      return this.$store.getters["menu/originName"];
    },
    loadingContent() {
      return this.$store.state.menu.loading;
    },
    canRedirectToProduct() {
      return this.$route.meta.canRedirectToProduct ? true : false;
    },
    isWebOrdersEnabled() {
      //Viene del back dependiendo si el dynamicinfo WebOrders_On_Off es 1 o no
      return this.$store.state.menu.profile.isWebOrdersEnabled;
    },
    webordersValidation() {
      if (this.$route.name === "MenuWeborders") {
        //Entro aca cuando la ruta tiene menu/weborders
        return this.isWebOrdersEnabled;
      }
      return true;
    },
    isMenu() {
      if (this.$route.name === "Menu") {
        return true;
      }
      return false;
    },
    errorMessage() {
      if (!this.webordersValidation) {
        return "No esta habilitada esta función, contáctese con el local directamente!";
      }
      if (!this.isShopOpen) {
        return "El comercio se encuentra cerrado! Podrás pedir cuando vuelva a abrir. Gracias!";
      }
      if (!this.isPickupUpPointOpen && !this.hasChildShops) {
        return "El punto de retiro seleccionado está cerrado! Podrás elegirlo cuando vuelva a abrir. Gracias!";
      }
      if (!this.isThereAtLeastOneChildShopOpen && this.hasChildShops) {
        return "El comercio se encuentra cerrado! Podrás pedir cuando vuelva a abrir. Gracias!";
      }
      return "No se encontraron productos!";
    },
  },
};
</script>
<style scoped>
.menu-container {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
