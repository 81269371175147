<template>
  <!-- <v-row
    justify="center"
    class="mb-5 mt-0 card-border"
    :class="'main-box-can-redirect'"
    @click="pushProductRoute(code)"
  >
    <v-col cols="12" class="py-0 px-5">
      <v-row class="rounded-lg my-2" justify="space-between">
        <v-col cols="8">
          <v-row align-content="space-between">
            <v-col cols="12" class="pb-0">
              <h6 class="text-h3 font-weight-normal mb-1 wrap-text grey-text">
                {{ name }}
              </h6>
              <h6 class="text-h3 font-weight-normal mb-1 wrap-text">
                {{ description }}
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <base-img
            v-if="image"
            :aspect-ratio="1"
            class="rounded-sm"
            :src="image"
            cover
            position="center"
            max-width="12rem"
          />
          <base-img
            v-else
            :aspect-ratio="1"
            :src="require('@/assets/no-image.png')"
            class="rounded-lg"
            max-width="12rem"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row> -->
  <div
    justify="center"
    class="card-border"
    :class="'main-box-can-redirect'"
    @click="pushProductRoute(code)"
  >
    <base-img
      v-if="image"
      :aspect-ratio="1"
      class="rounded-sm shop-logo"
      :src="image"
      cover
      position="center"
    />
    <base-img
      v-else
      :aspect-ratio="1"
      :src="require('@/assets/no-image.png')"
      class="rounded-lg"
      max-width="12rem"
    />
    <div class="shop-card-description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  methods: {
    pushProductRoute(code) {
      window.location.href = `menu/weborders?commerceId=${code}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-text {
  overflow-wrap: break-word;
}

.card-border {
  border: 0.08rem solid rgba(232, 232, 232, 1);
  // border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
}

.shop-logo {
  max-height: 150px;
  object-fit: scale-down;
}

.shop-card-description {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.grey-text {
  color: grey;
}

.main-box-can-redirect:hover {
  cursor: pointer;
  background-color: rgba(232, 232, 232, 0.5);
}
</style>
