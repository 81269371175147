var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{attrs:{"id":"menu","space":"0"}},[(_vm.backgroundMenuUrl)?_c('v-container',[_c('v-img',{attrs:{"height":"100%","width":"100%","src":_vm.backgroundMenuUrl}})],1):(_vm.hasChildShops)?_c('v-container',[(_vm.shopDescription)?_c('v-container',[(_vm.shopDescription)?_c('div',{staticClass:"text-center font-weight-thin",domProps:{"innerHTML":_vm._s(_vm.shopDescription)}}):_vm._e()]):_vm._e(),(
        !_vm.loadingContent &&
        _vm.webordersValidation &&
        _vm.isThereAtLeastOneChildShopOpen
      )?_c('v-slide-x-transition',{attrs:{"hide-on-leave":""}},[_c('div',[_c('shop-card-list',{attrs:{"childShops":_vm.childShops}})],1)]):_c('base-section',{attrs:{"space":"0"}},[(_vm.loadingContent)?_c('v-row',{staticClass:"py-12",attrs:{"justify":"center"}},[_c('base-progress-circular')],1):_c('v-row',{staticClass:"pt-12",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('v-icon',{staticClass:"mx-auto mb-6",attrs:{"size":"120","color":"primary lighten-2"}},[_vm._v(" mdi-cart-off ")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('base-subheading',{staticClass:"text-center text--disabled",attrs:{"title":_vm.errorMessage}})],1)],1)],1)],1):_c('v-container',{staticClass:"menu-container"},[(_vm.shopDescription)?_c('v-container',[(_vm.shopDescription && !_vm.isMenu)?_c('div',{staticClass:"text-center font-weight-thin",domProps:{"innerHTML":_vm._s(_vm.shopDescription)}}):_vm._e()]):_vm._e(),(
        _vm.groupedProductsByType &&
        _vm.groupedProductsByType[0] &&
        !_vm.loadingContent &&
        ((_vm.webordersValidation && _vm.isShopOpen) || _vm.isMenu) &&
        _vm.isPickupUpPointOpen
      )?_c('v-slide-x-transition',{attrs:{"hide-on-leave":""}},[_c('div',[_c('content-card-list',{attrs:{"can-redirect-to-product":_vm.canRedirectToProduct,"groupedProductsByType":_vm.groupedProductsByType,"originName":_vm.originName,"hasToCheckAvailability":_vm.hasToCheckAvailability}})],1)]):_c('base-section',{attrs:{"space":"0"}},[(_vm.loadingContent)?_c('v-row',{staticClass:"py-12",attrs:{"justify":"center"}},[_c('base-progress-circular')],1):_c('v-row',{staticClass:"pt-12",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('v-icon',{staticClass:"mx-auto mb-6",attrs:{"size":"120","color":"primary lighten-2"}},[_vm._v(" mdi-cart-off ")])],1),_c('v-col',{attrs:{"cols":"10"}},[_c('base-subheading',{staticClass:"text-center text--disabled",attrs:{"title":_vm.errorMessage}})],1)],1)],1)],1),_c('modal-news')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }